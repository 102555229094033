import HowRegister from './HowRegister'
import RegisterSuppliers from './RegisterSuppliers'
import ShortDescription from './ShortDescription'
import './styles.scss'
import WaitYou from './WaitYou'

const RecruitingSuppliersComponent = () => {
  return (
    <main className="recruiting-suppliers">
      <section className="section01">
        <div>
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/banner-lp-recrutamento-fornecedores-desktop.png"
            alt="banner-principal"
          />
        </div>
      </section>
      <ShortDescription />
      <RegisterSuppliers />
      <HowRegister />
      <WaitYou />
    </main>
  )
}

export default RecruitingSuppliersComponent
