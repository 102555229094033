const HowRegister = () => {
  return (
    <section className="section-04">
      <h2>COMO SE CADASTRAR?</h2>
      <p>
        Se cadastrar para ser nosso fornecedor é muito fácil! Basta seguir os
        passos abaixo:
      </p>
      <div>
        <div className="card-cadastros">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/website.png"
            alt="banner-card1"
          />
          <h2>PASSO 1</h2>
          <p>
            Preencha o formulário de <br /> cadastro de novo fornecedor.
          </p>
          <a
            className="btn-register"
            href="https://docs.google.com/forms/d/e/1FAIpQLSePY2KfxzG51lJxvWtSGQ19nWrEHxxA-C6zTh_Rwj9f8FkkRw/viewform"
          >
            PREENCHER FORMULÁRIO &nbsp;&nbsp;&nbsp;&nbsp; {'>'}
          </a>
        </div>
        <div className="card-cadastros">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/hourglass.png"
            alt="banner-card2"
          />
          <h2>PASSO 2</h2>
          <p>
            Aguarde a avaliação do <br /> departamento de produção.
          </p>
        </div>
        <div className="card-cadastros">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/handshake.png"
            alt="banner-card3"
          />
          <h2>PASSO 3</h2>
          <p>
            Após a avaliação, você poderá <br /> ser chamado para uma conversa{' '}
            <br /> com a nossa equipe para nos <br /> conhecermos melhor.
          </p>
        </div>
      </div>
    </section>
  )
}

export default HowRegister
