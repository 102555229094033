const ShortDescription = () => {
  return (
    <section className="section-02">
      <p>
        A Decathlon é uma empresa de esportistas que querem compartilhar essa
        paixão com o máximo de pessoas possível, através de inovação no
        desenvolvimento de produtos que acompanhem a evolução de adeptos da
        prática esportiva como nós.
      </p>

      <p>
        Se você quer fazer parte da nossa cadeia de fornecedores de produtos e
        matérias-primas, confira nossos pré-requisitos e processo de cadastro!
      </p>
    </section>
  )
}

export default ShortDescription
