const RegisterSuppliers = () => {
  return (
    <section className="section-03">
      <img
        src="https://decathlonstore.vteximg.com.br/arquivos/img-section-3-recrutamento-fornecedores.png"
        alt="banner-principal"
      />
      <div>
        <h2>o que você precisa ter para se cadastrar?</h2>
        <div className="container-items">
          <ul>
            <li>
              <div id="tick-mark" />
              <p>Ter uma empresa legalmente constituída;</p>
            </li>
            <li>
              <div id="tick-mark" />
              <p>Ter todos os funcionários registrados;</p>
            </li>
            <li>
              <div id="tick-mark" />
              <p>
                Ter capacidade produtiva interna ou através de contratação de
                terceiros identificados;
              </p>
            </li>
            <li>
              <div id="tick-mark" />
              <p>Atender aos requisitos do Código de Conduta da Decathlon.</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default RegisterSuppliers
