const WaitYou = () => {
  return (
    <section className="section-05">
      <h3>venha ser um fornecedor decathlon</h3>

      <h2>Esperamos você!</h2>
      <img
        src="https://decathlonstore.vteximg.com.br/arquivos/banner-footer-lp-recrutamento-forncedores-desktop.png"
        alt="banner-principal-footer"
      />
      <div className="div-blur" />
      <a
        className="btn-register"
        href="https://docs.google.com/forms/d/e/1FAIpQLSePY2KfxzG51lJxvWtSGQ19nWrEHxxA-C6zTh_Rwj9f8FkkRw/viewform"
      >
        FAÇA O SEU CADASTRO &nbsp;&nbsp;&nbsp;&nbsp; {'>'}
      </a>
    </section>
  )
}

export default WaitYou
